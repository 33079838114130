import React, {useEffect, useRef, useState} from 'react';
import ReactToPrint from 'react-to-print';
import {PrinterFill} from 'react-bootstrap-icons';
import {Button, Col, Row} from 'react-bootstrap';
import {calSyring, calSyringSize2} from '../functions/utils';
import { format } from 'date-fns';
import th from 'date-fns/locale/th';
import {QRCodeSVG} from 'qrcode.react';
import {apiService} from '../functions/api-service';
import { OrderTransaction } from '../type';
import moment from 'moment';
import {convertTextToEnglishQwerty, convertTextToThaiQwerty} from "../functions/convertText";
import {useContexts} from "../context/AppContext";

type Props = {
    data: OrderTransaction | null;
    orderQrTranList:Array<OrderTransaction> | null;
    contentType:string
    site:string | undefined
};

const setPage = (r: OrderTransaction, site:string |undefined) => {
    let pull= r.chemoPull != '0.0' ? r.chemoPull: r.chemoPullGem;
    let nss = (r?.scsId && r?.med && r?.med?.scsList.length > 0 && r.med.scsList.find(e => e.id === r.scsId)?.scs?.name) ??''
    let qrNss = (nss??'')
    let total_val= 0
    if (typeof qrNss === "string" && pull) {

        let nssVal =(r?.scsId && r?.med && r?.med?.scsList.length > 0 && r.med.scsList.find(e => e.id === r.scsId)?.scs?.solvent) ?? 0;
        total_val = parseFloat(String(nssVal)) + parseFloat(pull);
    }
    nss = nss != '' ? 'in '+nss :''

    let scs_machine_id = '',stable_timetxt = '',scs_code = '',stable_time = '',temperature = '', stable_type= '',expHr: string | number = '';

    if(r?.scsId && r.med){
        stable_time = ( r.med.scsList.find(e => e.id === r.scsId)?.stableTime) ??''
        temperature = ( r.med.scsList.find(e => e.id === r.scsId)?.temperature) ??''
        stable_type = ( r.med.scsList.find(e => e.id === r.scsId)?.stableType) ??''
        scs_code = ( r.med.scsList.find(e => e.id === r.scsId)?.scsCode) ??''
        scs_machine_id = (( r.med.scsList.find(e => e.id === r.scsId))?.scs?.machine?.itemId)??''

        expHr =  (stable_type === 'ชัวโมง' ? stable_time : parseInt(stable_time) * 24)
        temperature = temperature != '' ? 'เก็บรักษาที่อุณหภูมิ '+temperature+' องศา' :''
        stable_timetxt = stable_time != '' ? 'ใช้ภายใน '+stable_time+' '+stable_type :''

    }

    if(r?.orderDate){
        r.orderDate = new Date(r.orderDate);
    }
    let min = moment().format('HH:mm');
    let temp = moment(r.orderDate).format('DD/MM/YYYY')+' '+min
    let mfg = moment(temp,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm');
    let units: moment.unitOfTime.DurationConstructor = ( stable_type === 'ชัวโมง' ? 'hours' :'day');
    let exp = moment(mfg,'DD/MM/YYYY HH:mm').add(stable_time, units).format('DD/MM/YYYY HH:mm');
    let fullname = convertTextToEnglishQwerty(r?.person?.fullName.toString()??'',site)

    // let  xold = r.amountOld ?r.dosage1+'@'+r.dosageOld+' ml':'';
    // $text = $r_temp['id'].','.$r_temp['full_name'].','.$r_temp['trade_name'].','.$xold.','.$r_temp['hm_qty_old'].','.$r_temp['item_dosage'].','.$r_temp['hm_qty'].','.$r_temp['item_dosage2'].','.$r_temp['hm_qty2'].','.$r_temp['chemo_pull'].','.$r_temp['chemo_pull_gem'].','.$r_temp['solvent_scs'].','.$r_temp['solvent_scs_unit_old'].','.$r_temp['scs_name'].','.$r_temp['m_medical_name'].','.$r_temp['order_date'];
    // let qrValue = r.orderID+','+(r?.person?.fullName.toString())+','+(r?.med && r?.med.machineMedName)+','
    //     +xold+','+(r.amountOld??'')+','+r.dosage1+','+r.amount1+','
    //     +(r.dosage2??'')+','+(r.amount2??'')+','+(r.chemoPull??'')+','+(r.chemoPullGem??'')+','
    //     +'solvent_scs'+','+(r.scsGemUnitOld??'')+','+qrNss+','+(r.method?.name??"") +','
    //     +(r?.orderDate ? format(r?.orderDate,  "Y-MM-dd") : '');
    let chemoPull  = r?.scsGemMacId == '' && r.chemoPullGem ? r.chemoPullGem : r.chemoPull;
    let chemoPullGem  = r?.scsGemMacId != '' ? r.chemoPullGem: '0.00';
    let qrValue = r.orderID+','+(fullname)+','
        +(r?.medOld?.machine?.itemId??'')+','+(r.amountOld??'')+','+(r?.med?.machine?.itemId??'')+','+r.amount1+','
        +(r?.med2?.machine?.itemId??'')+','+(r.amount2??'')+','+(chemoPull??'')+','+(chemoPullGem??'')+','
        +(r?.scsGemMacId??'')+','+(r.scsGemUnitOld??'')+','+scs_machine_id+','+(r.method?.id??"") +','
        + (r?.orderDate ? format(r?.orderDate,  "Y-MM-dd") : '')+','+(r?.person?.hn.toString())
        +','+(r?.med?.machine?.density??'')+','+(r?.med2?.machine?.density??'');

    let qrValue2 = r.orderID+','+(fullname)+','
        +(r?.medOld?.machine?.itemId??'')+','+(r.amountOld??'')+','+(r?.med?.machine?.itemId??'')+','+r.amount1+','
        +(r?.med2?.machine?.itemId??'')+','+(r.amount2??'')+','+(chemoPull??'')+','+(chemoPullGem??'')+','
        +(r?.scsGemMacId??'')+','+(r.scsGemUnitOld??'')+','+scs_machine_id+','+(r.method?.code =='99' ? r.method?.code : r.method?.id??"") +','
        + (r?.orderDate ? format(r?.orderDate,  "Y-MM-dd") : '')+','+(r?.person?.hn.toString())
        +','+(r?.med?.machine?.density??'')+','+(r?.med2?.machine?.density??'')+','+(expHr??'');

    let componentToRender;
    switch (site) {
        case '1':
            componentToRender = (
                <Row className={"thsarabunnew pagebreak"} style={{margin: "5px",fontSize:"15px",fontWeight:"500"}}>
                <Col  className={"col-12 px-1"}> &nbsp;</Col>
                <Col  className={"col-12 px-1 mb-2"}> &nbsp;</Col>
                <Col className={"col-9 px-1 fw-bold"} style={{fontSize:"14px"}}>{r?.person?.hn+' '+r?.person?.fullName}</Col>
                <Col className={"col-3 px-1 fw-bold"} style={{fontSize:"12px",textAlign:"right"}}>{r.dept ??""}</Col>
                <Col className={"col-9 px-1 fw-bold "} style={{fontSize:"13px"}}>
                    {r?.med?.name}
                    <span style={{fontSize:"12px"}}>{' '+r.perTime+'mg ('+parseFloat(pull as string).toFixed(2)+'ml)'}</span>

                </Col>
                <Col className={"col-3 px-1 "} style={{fontSize:"12px",textAlign:"right"}}>
                    {r?.orderDate && (format(r?.orderDate,  "dd MMM", {locale: th}) + ' ' +
                        (Number(format(r?.orderDate,  "Y")) + 543).toString())}
                </Col>
                <Col  className={"col-12 px-1 fw-bold"} style={{fontSize:"13px",}}>
                    {''+nss+" "+(r.method?.name??"") + " " + (r.time?.name??"")}
                </Col>
                <Col  className={"col-4 px-1"} style={{fontSize:"8px"}}>

                    <Col className={"text-center px-1"} style={{minHeight: "75px"}}>
                        <QRCodeSVG
                            id={"qr-code-voucher"}
                            size={256}
                            value={qrValue}
                            bgColor={"#ffffff"}
                            includeMargin={true}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        />
                    </Col>
                    <Col className={"text-center"} >{r.orderID}</Col>
                </Col>

                <Col  className={"col-8 px-1"} style={{fontSize:"14px"}}>
                    <Col>{calSyring(pull ?? '', r.method?.name ?? '')}</Col>
                    <Col>
                        <span className={"fw-bold"}>MFG: </span>{mfg}
                    </Col>
                    <Col>
                        <span className={"fw-bold"}>EXP: </span>{exp}
                    </Col>
                    <Col><span className={""}>{temperature}</span></Col>

                    <Col className={"fw-bold"}>{(r.noDay ? ' Day: '+r.noDay  : '')+ (r.noInDay ? ' ขวดที่  '+r.noInDay  : '')}</Col>

                    <Col style={{fontSize:"12px"}}>
                        <span className={"fw-bold"} style={{fontSize:"12px"}}>คำเตือน : </span>{r.med?.note}
                    </Col>
                </Col>
            </Row>
            );
            break;
        case '2':
            componentToRender = (
                <Row className={"thsarabunnew pagebreak"} style={{margin: "5px",fontSize:"15px",fontWeight:"500"}}>
                    <Col  className={"col-12 px-1"}> &nbsp;</Col>
                    <Col className={"col-11 px-0 py-0 "} style={{fontSize:"12px",textAlign:"right"}}>
                        {r.dept ??""}
                    </Col>
                    <Col className={"col-1 px-0 py-0 "} style={{fontSize:"12px",textAlign:"right"}}>&nbsp;</Col>

                    <Col className={"col-8 px-1 fw-bold"} style={{fontSize:"15px"}}>
                        {r?.person?.hn+' '+r?.person?.fullName}
                    </Col>
                    <Col className={"col-3 px-1 "} style={{fontSize:"12px",textAlign:"right"}}>
                        {r?.orderDate && (format(r?.orderDate,  "dd MMM", {locale: th}) + ' ' +
                            (Number(format(r?.orderDate,  "Y")) + 543).toString())}
                    </Col>
                    <Col className={"col-12 px-1 fw-bold "} style={{fontSize:"13px"}}>
                        {r?.med?.name+' '+r.perTime+' mg (ปริมาตรยา '+parseFloat(pull as string).toFixed(2)+' ml)'}
                    </Col>
                    <Col className={"col-12 px-1 fw-bold "} style={{fontSize:"13px"}}>
                        {'(Total volume   '+(total_val).toFixed(2)+' ml)'}
                    </Col>
                    <Col  className={"col-12 px-1 fw-bold"} style={{fontSize:"13px",}}>
                        {''+nss+" "+(r.method?.name??"") + " " + (r.time?.name??"")}
                    </Col>
                    <Col  className={"col-4 px-1"} style={{fontSize:"8px"}}>

                        <Col className={"text-center px-1"} style={{minHeight: "75px"}}>
                            <QRCodeSVG
                                id={"qr-code-voucher"}
                                size={256}
                                value={qrValue2}
                                bgColor={"#ffffff"}
                                includeMargin={true}
                                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            />
                        </Col>
                        <Col className={"text-center fw-bold"} style={{fontSize:"9px"}}>{r.orderID}</Col>
                    </Col>

                    <Col  className={"col-8 px-1"} style={{fontSize:"14px"}}>
                        <Col>
                            {calSyringSize2(pull ?? '', r.method?.name ?? '')},Med quantity : {r.amount1}
                        </Col>
                        <Col>

                            <span className={"fw-bold"}>MFG: {moment(r.orderDate).format('DD/MM/YYYY ')}
                                {r.mfgDate ? r.mfgDate : min}
                            </span>
                        </Col>
                        <Col>
                            <span className={"fw-bold"}>EXP:
                                {r.mfgDate ?
                                    moment(exp,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY ')+ r.mfgDate :
                                    moment(exp,'DD/MM/YYYY HH:mm').format('DD/MM/YYYY HH:mm')}
                            </span>
                        </Col>
                        <Col>
                            <span className={""}>{temperature}</span>
                        </Col>

                        <Col className={"fw-bold"}>{(r.noDay ? ' Day: '+r.noDay  : '')+ (r.noInDay ? ' ขวดที่  '+r.noInDay  : '')}</Col>

                        <Col style={{fontSize:"8px"}}>
                            <span className={"fw-bold"} style={{fontSize:"12px"}}>คำเตือน : </span>{r.med?.note}
                        </Col>
                    </Col>
                </Row>
            );
            break;
        default:
            componentToRender = <Row>error call admin</Row>; // or any other default value
    }
    return (
        componentToRender
    );
};


class ContentGroupChemo extends React.Component<Props>  {

    render() {
        const {data, orderQrTranList} = this.props
        const site = this.props.site
        let contentType: string;
        contentType = this.props.contentType;

        return (<div className="print-container" style={{minHeight:"", margin: "", padding: "0" }}>
            {orderQrTranList && orderQrTranList.map((r, i) => (
                <>
                    {data && r?.person?.hn == data?.person?.hn ? setPage(r,site) : ''}
                    {!data ? setPage(r,site) :''}

                </>
            ))}
        </div>)
    }
}

const BtnPrintQrGroupChemo = ({ data,orderQrTranList,contentType,site }: Props) => {
    const componentRef = useRef(null);


    return (
        <span className={""}>
            <ReactToPrint
                trigger={() =>
                    <Button style={{marginLeft: "2px"}}
                            variant="warning" size={contentType == 'QrGroupByDate' ? "lg":"sm"}>
                        <PrinterFill/> พิมพ์ Qr กลุ่ม</Button>}
                content={() => componentRef.current}
                pageStyle={`@media print {
                    @page {
                      size:  3.54in 2.75in;               
                    }
                    .pagebreak {3.5
                        clear: both;
                        page-break-after: always;
                    }
                  }`}
            />
            <div className={"hide"} style={{display:"none"}}>
            {/*<div>*/}
                <ContentGroupChemo ref={componentRef} data={data} site={site} orderQrTranList={orderQrTranList} contentType={'QrGroup'} />
            </div>
        </span>
    );
};

export default BtnPrintQrGroupChemo;